<template>
    <div class="flex flex-col flex-grow p-8">
        <!-- Tabs for navigation -->
        <div class="flex border-b mb-8">
            <div
                v-for="tab in tabs"
                :key="tab"
                class="cursor-pointer pb-2 mx-4 text-[#4C535F] text-[20px]"
                :class="tab === activeTab ? 'border-b border-blue-500 text-blue-500 px-1' : ''"
                @click="selectTab(tab)"
            >
                {{ tab }}
            </div>
        </div>

        <!-- Form Fields -->
        <div class="grid grid-cols-2 gap-8">
            <div>
                <!-- Upload Button -->
                <button class="flex flex-col items-center justify-center w-[160px] h-[160px] border-2 border-dashed rounded-lg border-[#E0E4EC] bg-[#F7F9FC] shadow-lg">
                    <!-- Icon (placeholder for the upload icon) -->
                    <div class="mb-2">
                        <!-- You can use an SVG or font icon here -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-[#8D98AA]">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5v-9m-3 3l3-3 3 3m6 9v-9a9 9 0 10-18 0v9m18 0H6" />
                        </svg>
                    </div>

                    <!-- Text -->
                    <span class="text-[#8D98AA] text-[14px] font-manrope font-medium">{{ $t("Upload your photo") }} </span>
                </button>
            </div>

            <InputWithLabel label="Portfolio" placeholder="Please enter the link to your Portfolio" inputWidth="450px" inputHeight="52px" />
            <hr class="h-[1px] bg-[#F5F8FF] w-full" />
            <hr class="h-[1px] bg-[#F5F8FF] w-full" />

            <!-- Full name >
        <input type="text" placeholder="Please enter your full name" class="w-full p-3 border border-gray-300 rounded-lg" /-->
            <InputWithLabel label="Full name" placeholder="Please enter your full name" inputWidth="500px" inputHeight="52px" />
            <!-- Email>
        <input type="email" placeholder="Please enter your email" class="w-full p-3 border border-gray-300 rounded-lg" /-->
            <InputWithLabel label="Email" placeholder="Please enter your email" inputWidth="450px" inputHeight="52px" />
            <!-- Country>
        <input type="text" placeholder="Please enter your Country" class="w-full p-3 border border-gray-300 rounded-lg" /-->
            <InputWithLabel label="Country" placeholder="Please enter your Country" inputWidth="450px" inputHeight="52px" />
            <div>
                <label :for="you" class="block w-[100px] h-[18px] text-[16px] font-manrope text-lg font-medium text-[#4C535F] text-left mb-[4px]">{{ $t("You are") }} </label>
                <!-- Dropdown (You are) -->
                <select
                    id="you"
                    style="width: 450px; height: 52px"
                    :class="['w-full block mt-4 px-4 py-3 rounded-lg', 'text-[14px] leading-[15.74px] font-manrope font-medium text-[#8D98AA]', 'border-[#E0E4EC] bg-[#E0E4EC]']"
                >
                    <option value=""></option>
                    <!-- Add options here -->
                </select>
            </div>
            <InputWithLabel
                class="placeholder-[#8D98AA] 'resize-none' placeholder:text-start placeholder:text-[14px] placeholder:font-medium placeholder:font-manrope placeholder:leading-[15.74px]"
                label="Bio"
                placeholder="Write your Bio here e.g your hobbies, interests etc."
                inputWidth="952px"
                inputHeight="150px"
            />
        </div>

        <!-- Buttons -->
        <div class="flex space-x-4 mt-8">
            <button class="px-6 py-2 bg-blue-500 text-white rounded-lg">{{ $t("Update Profile") }}</button>
            <button class="px-6 py-2 bg-gray-300 text-black rounded-lg">{{ $t("Reset") }}</button>
        </div>
    </div>
</template>

<script>
import InputWithLabel from "./ReusableComponents/InputWithLabel.vue";

export default {
    components: {
        InputWithLabel,
    },
    data() {
        return {
            tabs: ["Skills", "Experience", "Education", "Certifications & Awards", "Preferences", "Account Setting"],
            activeTab: "Account Setting",
        };
    },
    methods: {
        selectTab(tab) {
            this.activeTab = tab;
            // You can implement the scroll or redirect here
            if (tab === "Account Setting") {
                // Implement your redirection logic here (if using router)
                this.$router.push({ name: "accountSettings" });
            } else if (tab === "Experience") {
                // Scroll to Experience section or perform any other action
                this.$router.push({ name: "accountExperience" });
            }
        },
    },
};
</script>

<style scoped>
/* Additional styling here */
</style>
